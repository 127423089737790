<template>
  <div class="job-details">

    <!-- grey intro -->
    <div class="content">
      <div class="bg-site-light pt-4 pb-4 mb-4">
        <div class="container-fluid px-5">
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
            <div class="flex-sm-fill">
              <h1 class="h3 font-w700 mb-0">
                Files, Images and Templates
              </h1>
            </div>
            <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
              <!--
              <a href="javascript:void(0)" class="btn btn-outline-secondary mr-1"><b-icon icon="plus-square-fill"></b-icon> New Order</a>
              <a href="javascript:void(0)" class="btn btn-secondary"><b-icon icon="gear-fill"></b-icon></a>
              -->
            </div>
          </div>
        </div>
      </div>

      <!-- Grid Wrapper / Layout -->
      <div class="details-wrapper container bg-white py-4 rounded shadow">

        <!-- GRID-AREA: INTRO -->
        <div class="details-intro border-bottom">
          <p class="lead text-secondary">Your files</p>
          <p>
            Download one of your .pdfs, or if available view how an image look on a basic template.
            Complete the selection below to show download button.
          </p>
        </div>

        <!-- GRID-AREA: CONTENT -->
        <div class="details-content">
          <div class="row">
            <div class="col-sm-12">
              <div class="mb-3">
                <b-form @submit="function() {return false}" @reset="function() {return false}">

                  <b-form-group
                    v-if="userHasAnyOfRoles(allow_selection_for)"
                    label="Account:"
                    label-for="input-account"
                    description="Only available for admins."
                  >
                    <multiselect 
                      id="input-account"
                      v-model="form.account"
                      trackBy="account"
                      label="name"
                      size="md"
                      :options="userAccounts"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Select account..."
                      @input="refreshData()"
                      :disabled="loading"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group
                    label="File:"
                    label-for="input-file"
                    description="These are the files on your FTP (only files available for integration is shown)."
                  >
                    <multiselect
                      id="input-file"
                      v-model="form.file"
                      trackBy="path"
                      label="name"
                      size="md"
                      :options="userfiles"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Select your file..."
                      :disabled="loading"
                      @input="form.size = null"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group
                    v-show="activeFileIsImage"
                    label="Size:"
                    label-for="input-size"
                  >
                    <multiselect
                      id="input-size"
                      v-model="form.size"
                      trackBy="value"
                      label="text"
                      size="md"
                      :options="sizes"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Select a size..."
                      :disabled="loading"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group
                    v-show="activeFileIsImage"
                    label="Template:"
                    label-for="input-template"
                  >
                    <multiselect
                      id="input-template"
                      v-model="form.template"
                      trackBy="value"
                      label="text"
                      size="md"
                      :options="templates"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Select a template..."
                      :disabled="loading"
                    ></multiselect>
                  </b-form-group>

                </b-form>

                <div v-if="activeFileIsImage" class="mt-4">
                  <b-link :href="templateUrl" target="_blank" :disabled="!selectionIsComplete" class="btn btn-outline-primary">Download PDF</b-link>
                  <br>
                  <span v-show="!selectionIsComplete" class="small text-danger font-italic">Please complete the selection to enable the link.</span>
                </div>

                <div v-if="activeFileIsPdf" class="mt-4">
                  <b-link :href="form.file.path" target="_blank" class="btn btn-outline-primary">Download PDF</b-link>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- GRID-AREA: SIDE -->
        <div class="details-side">
          <div class="row">
            <div class="col-sm-12">
              <div v-show="activeFileIsImage && form.template">
                <p class="text-muted mb-2 text-uppercase">Template details</p>
                <p class="text-monospace mb-2">
                  &gt; <b>{{ form.template.text }}</b>
                </p>
                <p>
                  {{ activeTemplateDescription }}
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div><!-- end details-content -->

  </div><!-- end wrapper -->
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

//import OrderContent from '@/components/OrderContent/OrderContent.vue';

export default {
  components: {
    //OrderContent,
  },

  data() {
    return {
      // are we loading?
      loading: false,
      // any errors?
      error: false,
      // Some roles may switch account
      allow_selection_for: ['superadmin','puadmin'],
      // Form data (both objects and values)
      form: {
        account: null,
        file: null,
        size: null,
        template: null,
      },
      // Predefined sizes
      sizes: [
        {text: '21x30', value: '210x300'},
        {text: '30x40', value: '300x400'},
        {text: '40x50', value: '400x500'},
        {text: '50x70', value: '500x700'},
        {text: '70x100', value: '700x1000'},
        {text: '100x140', value: '1000x1400'},
      ],
      // Predefined templates
      templates: [
        {
          text: 'Autorotate with bleed',
          value: 'autorotate_with_bleed',
          description: 'This will rotate the image to the orientation that best fit the paper layout (if needed). It will place and scale the image in a way so that it cover the pdf area. It will also expand the pdf to include both trim-marks and a 3mm bleed area.'
        },
        {
          text: 'With bleed',
          value: 'with_bleed',
          description: 'This will place and scale the image in a way so that it cover the pdf area. It will also expand the pdf to include both trim-marks and a 3mm bleed area.'
        },
      ],
      // Holder for account files - populated on created -or- account-selection
      userfiles: [],
    }
  },

  computed: {
    // USER MODULE
    ...mapGetters('user', [
      'currentUser',
      'userAccounts',
      'userAccountName',
      'userHasRole',
      'userHasAnyOfRoles',
    ]),

    // Is the selection complete to show a template download button?
    selectionIsComplete () {
      return this.form.size?.value && this.form.template?.value && this.form.file?.path;
    },

    // The template URL
    templateUrl () {
      if (!this.selectionIsComplete) {
        return '#';
      }
      return `https://integration24.systembyran.se/png-to-pdf/pdfmotor.php?template=${this.form.size?.value}_${this.form.template?.value}&url=${this.form.file?.path}`;
    },

    // Filter for only the image files (.jpg)
    imageFiles () {
      return this.userfiles.filter(item => item.path.endsWith('.jpg'));
    },

    // Number of files
    filesCount() {
      return this.userfiles.length;
    },

    // Is the active file an image (.jpg)
    activeFileIsImage() {
      return this.form.file?.path.toLowerCase().endsWith('.jpg');
    },

    // Is the active file a pdf
    activeFileIsPdf() {
      return this.form.file?.path.toLowerCase().endsWith('.pdf');
    },

    // activeImage description
    activeTemplateDescription() {
      return this.templates.find(item => item.value === this.form.template?.value)?.description ?? '';
    }

  },

  async created () {
    this.$store.commit("SET_SITE_SETTINGS", {'sidebar_current': 'templating'});
    // set form defaults
    // - start template is the forst in template-list
    this.form.template = this.templates[0];
    // - start size is the forst in size-list
    // this.form.size = this.sizes[0];
    // - if logged in user has an account, use that, fallback no null (for others like admins with no account)
    this.form.account = this.currentUser.account ? {account: this.currentUser.account} : null;
    
    // fetch files
    await this.fetchData();
  },

  methods: {
    ...mapActions('user', [
      'fetchPrintFiles',
    ]),

    // Fetch account files
    async fetchData() {
      // return without making a call if we have no account
      const fetchAccount = this.form.account?.account;
      if (! fetchAccount) {
        return;
      }

      // Reset error and set loading state
      this.error = null;
      this.loading = true;

      // Make the call to fetch print files - and populate the result to list
      const response = await this.fetchPrintFiles(fetchAccount);
      this.userfiles = response?.data?.files ?? [];

      // If we didn't get any files - show a message
      if (!this.userfiles.length) {
        this.$showIt("Sorry, we didn't find any files!");
      }

      // Remove loading state
      this.loading = false;
    },

    // What to do when admin selects a new account
    async refreshData() {
      // - remove file selected
      this.form.file = null;
      // - and fetch new files
      await this.fetchData();
    },
  },
}
</script>

<style lang="scss" scoped>
  // --------------------
  // Layout
  // --------------------
  .details-intro {
    grid-area: in;
  }
  .details-content {
    grid-area: main;
  }
  .details-side {
    grid-area: side;
  }

  @media (min-width: 422px) {
    .details-wrapper {
      display: grid;
      gap: 1em;
      grid-auto-rows: minmax(56px, auto);
      grid-template-columns: 1fr;
      grid-template-areas:
        "in"
        "main"
        "side";
    }
  }

  @media (min-width: 1140px) {
    .details-wrapper {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-auto-rows: minmax(56px, auto);
      grid-template-areas:
        "in in in in in in in in in"
        "main main main main main main side side side";
    }
    .details-side {
      padding-inline: 1rem;
      border-left: 1px solid #dadada;
    }
  }
</style>